import { useState } from "react";
import PropTypes from "prop-types";
import { transformImage } from "../utils/image-utils";
import { SkeletonLoader } from "./SkeletonLoader";

const IMAGE_SIZES = [470, 640, 768, 1024, 1280, 1536, 1920, 2560];

export const Image = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const srcSet = IMAGE_SIZES.map((imageSize) => [
    `${transformImage(props.image?.filename, {
      width: imageSize,
      height: 0,
    })} ${imageSize}w`,
  ]).join(", ");

  return (
    <>
      <figure
        className={props?.className}
        style={{ display: isLoading ? "none" : "block" }}
      >
        <img
          className="object-cover h-full w-full rounded-[inherit]"
          data-testid="news-image"
          src={props?.src}
          srcSet={srcSet}
          alt={props?.alt || props?.image?.alt}
          onLoad={() => setIsLoading(false)}
        />
        {props.label && (
          <figcaption data-testid="image-label" className="text-xs mt-2">
            {props?.label}
          </figcaption>
        )}
      </figure>
      {isLoading && <SkeletonLoader className={props?.className} />}
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string.isRequired,
  image: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};
