/* eslint-disable max-len */
const en = {
  translation: {
    homepage: {
      readNewsitem: 'Read news item',
      seeAll: 'See all',
      back: 'Back',
      latestNews: 'Latest news',
      quickLinks: 'Quick links',
    },
    navigation: {
      more: 'More',
      previous: 'Previous',
      next: 'Next',
      toggle: 'Toggle navigation menu',
      backToOverview: 'Back to overview',
      home: 'Home',
      news: 'News',
      workplace: 'Workplace',
      hr: 'HR',
    },
    404: {
      oops: 'Oops!',
      notFoundTitle: 'Page not found',
      notFoundText:
        'The page you are looking for cannot be found. Please go back to the <homeLink>Homepage</homeLink> and try again.',
    },
    storyblok: {
      errorTitle: 'An error happened.',
      errorMessage: 'Please try again later',
      noPostsFound: 'No posts found',
    },
    news: {
      title: 'News',
      subTitle: 'Search for and find the latest news',
      likes_one: '{{count}} like',
      likes_other: '{{count}} likes',
      relatedPostsTitle: 'Related posts',
      search: 'Type to search...',
    },
    settings: {
      title: 'Settings',
      subTitle: 'From here you are able to adjust the app settings.',
      language: 'Preferred language',
      notifications: 'Notifications',
      en: 'English language',
      lt: 'Lietuvių kalba',
      enableNotifications: 'Turn on notifications',
    },
    login: {
      enterUsername: 'Username',
      enterPassword: 'Password',
      signInButton: 'Sign in',
      signInTitle: 'Sign in to WeMove',
      error: 'Failed to login, please check your credentials and try again',
      forgotPassword: 'I forgot my password',
    },
  },
};

export default en;
