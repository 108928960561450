import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowIcon } from './icons/Icons';
import { PAGE_TYPE } from '../constants/storyblok';
import { DEFAULT_LANGUAGE } from '../constants/language';
import { transformImage } from "../utils/image-utils";

export const NewsHeader = ({ location, story, category }) => {
  const { t, i18n } = useTranslation();

  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <header
      data-testid="news-header"
      style={{ '--image-url': `url(${transformImage(story?.content?.featured?.filename, {
        width: 1440,
        height: 0,
})})` }}
      className={`relative flex flex-col min-h-[40vh] lg:min-h-[45vh] bg-cover bg-center bg-[image:var(--image-url)] rounded-b-2xl ${
        PAGE_TYPE.newsDetail === location ? 'justify-between' : 'justify-end'
      }`}
    >
      {PAGE_TYPE.newsDetail === location && (
        <button
          onClick={goToPreviousPath}
          className="pt-16 px-5 lg:px-14 inline-flex self-start items-center text-white leading-[0px] hover:text-brand-secondary"
          title={t('homepage.seeAll')}
        >
          <ArrowIcon className="rotate-180 mr-3" />
          {t('homepage.back')}
        </button>
      )}

      <div className="left-0 right-0 bottom-0 bg-gradient-to-t from-black p-5 lg:px-14 lg:pb-9 h-2/3 rounded-b-2xl flex justify-end flex-col">
        {category?.name && (
          <span className="self-start py-2 px-3 font-semibold bg-brand-primary text-brand-secondary rounded-xl">
            {i18n?.language !== DEFAULT_LANGUAGE
              ? category.translated_slugs[0].name
              : category.name}
          </span>
        )}
        <h1 className="mt-3.5 text-2xl lg:text-3xl leading-7 text-white font-medium">
          {story.content.title ?? story.name}
        </h1>
        {PAGE_TYPE.homepage === location && (
          <Link
            className="mt-2 text-white inline-flex font-medium items-center self-start hover:text-brand-secondary"
            title={story.content.title ?? story.name}
            to={`/${story.full_slug}`}
          >
            <span className="text-sm mr-2">{t('homepage.readNewsitem')}</span>
            <ArrowIcon />
          </Link>
        )}
      </div>
    </header>
  );
};

NewsHeader.propTypes = {
  location: PropTypes.string.isRequired,
  story: PropTypes.shape({
    full_slug: PropTypes.string.isRequired,
    content: PropTypes.shape({
      featured: PropTypes.shape({
        filename: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  category: PropTypes.shape({
    translated_slugs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    name: PropTypes.string.isRequired,
  }),
};
