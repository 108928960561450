import PropTypes from 'prop-types';

export const ArrowIcon = ({ className }) => (
  <svg
    data-testid="arrow-icon"
    className={`fill-brand-secondary ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="currentColor"
  >
    <path d="M13.927 5.14541C13.8783 5.03584 13.8174 4.93845 13.7322 4.85324L10.08 1.20106C9.72696 0.848018 9.14261 0.848018 8.78956 1.20106C8.43652 1.55411 8.43652 2.13845 8.78956 2.4915L10.8835 4.58541H0.913043C0.413913 4.58541 0 4.99932 0 5.49845C0 5.99758 0.413913 6.4115 0.913043 6.4115H10.8835L8.78956 8.50541C8.43652 8.85845 8.43652 9.4428 8.78956 9.79584C8.97217 9.97845 9.20348 10.0637 9.43478 10.0637C9.66609 10.0637 9.89739 9.97845 10.08 9.79584L13.7322 6.14367C13.8174 6.05845 13.8783 5.96106 13.927 5.8515C14.0243 5.63237 14.0243 5.37671 13.927 5.15758V5.14541Z" />
  </svg>
);

export const CheckIcon = ({ className }) => (
  <svg
    data-testid="check-icon"
    className={`fill-brand-secondary ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="currentColor"
  >
    <path d="M3.39644 8.08215L0.146441 4.83215C-0.0488135 4.63689 -0.0488135 4.32031 0.146441 4.12504L0.853531 3.41793C1.04879 3.22265 1.36539 3.22265 1.56064 3.41793L3.75 5.60727L8.43936 0.917925C8.63461 0.722671 8.95121 0.722671 9.14647 0.917925L9.85356 1.62504C10.0488 1.82029 10.0488 2.13687 9.85356 2.33214L4.10355 8.08217C3.90828 8.27742 3.5917 8.27742 3.39644 8.08215Z" />
  </svg>
);

export const ClockIcon = ({ className }) => (
  <svg
    data-testid="clock-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="currentColor"
  >
    <path d="M6.25 0C2.80128 0 0 2.80128 0 6.25C0 9.69872 2.80128 12.5 6.25 12.5C9.69872 12.5 12.5 9.69872 12.5 6.25C12.5 2.80128 9.69872 0 6.25 0ZM6.73077 11.5128V10.7372C6.73077 10.4744 6.51282 10.2564 6.25 10.2564C5.98718 10.2564 5.76923 10.4744 5.76923 10.7372V11.5128C3.23718 11.2821 1.21795 9.26282 0.98718 6.73077H1.76282C2.02564 6.73077 2.24359 6.51282 2.24359 6.25C2.24359 5.98718 2.02564 5.76923 1.76282 5.76923H0.98718C1.21795 3.23718 3.23718 1.21795 5.76923 0.98718V1.76282C5.76923 2.02564 5.98718 2.24359 6.25 2.24359C6.51282 2.24359 6.73077 2.02564 6.73077 1.76282V0.98718C9.26282 1.21795 11.2821 3.23718 11.5128 5.76923H10.7372C10.4744 5.76923 10.2564 5.98718 10.2564 6.25C10.2564 6.51282 10.4744 6.73077 10.7372 6.73077H11.5128C11.2821 9.26282 9.26282 11.2821 6.73077 11.5128Z" />
    <path d="M6.25011 3.84619C5.98729 3.84619 5.76934 4.06414 5.76934 4.32696V6.05132L3.98729 7.83337C3.8014 8.01927 3.8014 8.32696 3.98729 8.51286C4.08345 8.60901 4.20524 8.65388 4.32704 8.65388C4.44883 8.65388 4.57063 8.60901 4.66678 8.51286L6.58986 6.58978C6.6796 6.50004 6.73088 6.37824 6.73088 6.25004V4.32696C6.73088 4.06414 6.51293 3.84619 6.25011 3.84619Z" />
  </svg>
);

export const HomeIcon = ({ className }) => (
  <svg
    data-testid="home-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="currentColor"
  >
    <path d="M20.1908 5.68212L15.3356 1.43614C13.1508 -0.478713 9.84924 -0.478713 7.66441 1.43614L2.80924 5.68212C1.58331 6.75253 0.879311 8.28679 0.879311 9.90431V17.4686C0.879311 19.9662 2.91848 22 5.43104 22H17.569C20.0815 22 22.1207 19.9662 22.1207 17.4686V9.90431C22.1207 8.29869 21.4167 6.75253 20.1908 5.68212ZM14.231 20.2041H8.76897V16.3387C8.76897 14.8639 9.9949 13.6627 11.5 13.6627C13.0051 13.6627 14.231 14.8639 14.231 16.3387V20.2041ZM20.3 17.4567C20.3 18.9672 19.0741 20.2041 17.569 20.2041H16.0517V16.3387C16.0517 13.8767 14.0126 11.8786 11.5 11.8786C8.98745 11.8786 6.94828 13.8767 6.94828 16.3387V20.2041H5.43104C3.92593 20.2041 2.7 18.979 2.7 17.4567V9.89242C2.7 8.78632 3.18552 7.73969 4.02304 7.0023L8.87821 2.75632C10.3712 1.44803 12.6288 1.44803 14.1218 2.75632L18.977 7.0023C19.8145 7.73969 20.3 8.78632 20.3 9.89242V17.4567Z" />
  </svg>
);

export const LikeIcon = ({ className }) => (
  <svg
    data-testid="like-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="currentColor"
    className={className}
  >
    <path d="M15.5753 6.8564C15.1537 6.26452 14.4888 5.9321 13.7672 5.9321H12.2186C12.1375 5.9321 12.0808 5.88345 12.0565 5.85912C12.0321 5.82669 11.9997 5.77804 12.0159 5.71318C12.17 4.78888 12.0646 3.87269 11.7078 3.05379C11.1403 1.7403 10.0943 1.04302 9.3241 0.686277C8.87816 0.483579 8.36736 0.516011 7.95386 0.775464C7.54847 1.03492 7.29712 1.48085 7.29712 1.96733V4.10782C7.29712 5.1132 6.47822 5.9321 5.47284 5.9321H2.22968C0.997273 5.9321 0 6.92937 0 8.16177V12.2157C0 13.4481 0.997273 14.4454 2.22968 14.4454H12.4132C13.3781 14.4454 14.2213 13.8292 14.5294 12.9211L15.8834 8.86716C16.1104 8.17799 16.005 7.44827 15.5753 6.8564ZM1.21619 12.2157V8.16177C1.21619 7.60232 1.67023 7.14828 2.22968 7.14828H4.05396V13.2292H2.22968C1.67023 13.2292 1.21619 12.7752 1.21619 12.2157ZM14.724 8.48609L13.3699 12.54C13.2321 12.9535 12.8429 13.2292 12.4051 13.2292H5.27014V7.14828H5.47284C7.15118 7.14828 8.51331 5.78615 8.51331 4.10782V1.96733C8.51331 1.87814 8.56195 1.82138 8.60249 1.79706C8.63492 1.77274 8.716 1.7403 8.80519 1.78895C9.38896 2.05651 10.1673 2.58353 10.5889 3.54026C10.8565 4.14835 10.9295 4.8132 10.8078 5.51048C10.743 5.91588 10.8484 6.32938 11.1159 6.64559C11.3835 6.96991 11.7808 7.14828 12.2024 7.14828H13.751C14.0834 7.14828 14.3834 7.30233 14.5699 7.56989C14.7645 7.83745 14.8132 8.16988 14.7077 8.48609H14.724Z" />
  </svg>
);

export const MoreIcon = ({ className }) => (
  <svg
    data-testid="more-icon"
    className={className}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="22"
    viewBox="0 0 25 7"
    aria-hidden="true"
  >
    <path d="M12.5 0C10.5655 0 9 1.56545 9 3.5C9 5.43455 10.5655 7 12.5 7C14.4345 7 16 5.43455 16 3.5C16 1.56545 14.4345 0 12.5 0ZM12.5 5.09091C11.6218 5.09091 10.9091 4.37818 10.9091 3.5C10.9091 2.62182 11.6218 1.90909 12.5 1.90909C13.3782 1.90909 14.0909 2.62182 14.0909 3.5C14.0909 4.37818 13.3782 5.09091 12.5 5.09091Z" />
    <path d="M21.4092 0C19.4746 0 17.9092 1.56545 17.9092 3.5C17.9092 5.43455 19.4746 7 21.4092 7C23.3437 7 24.9092 5.43455 24.9092 3.5C24.9092 1.56545 23.3437 0 21.4092 0ZM21.4092 5.09091C20.531 5.09091 19.8183 4.37818 19.8183 3.5C19.8183 2.62182 20.531 1.90909 21.4092 1.90909C22.2874 1.90909 23.0001 2.62182 23.0001 3.5C23.0001 4.37818 22.2874 5.09091 21.4092 5.09091Z" />
    <path d="M3.59094 0C1.6564 0 0.0909424 1.56545 0.0909424 3.5C0.0909424 5.43455 1.6564 7 3.59094 7C5.52549 7 7.09094 5.43455 7.09094 3.5C7.09094 1.56545 5.52549 0 3.59094 0ZM3.59094 5.09091C2.71276 5.09091 2.00003 4.37818 2.00003 3.5C2.00003 2.62182 2.71276 1.90909 3.59094 1.90909C4.46912 1.90909 5.18185 2.62182 5.18185 3.5C5.18185 4.37818 4.46912 5.09091 3.59094 5.09091Z" />
  </svg>
);

export const NewsIcon = ({ className }) => (
  <svg
    data-testid="news-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="currentColor"
  >
    <path d="M14.218 0H4.78205C2.34051 0 0.358974 1.98154 0.358974 4.42308V18.5769C0.358974 21.0185 2.34051 23 4.78205 23H14.218C16.6595 23 18.641 21.0185 18.641 18.5769V4.42308C18.641 1.98154 16.6595 0 14.218 0ZM16.8718 18.5769C16.8718 20.0395 15.6805 21.2308 14.218 21.2308H4.78205C3.31949 21.2308 2.12821 20.0395 2.12821 18.5769V4.42308C2.12821 2.96051 3.31949 1.76923 4.78205 1.76923H14.218C15.6805 1.76923 16.8718 2.96051 16.8718 4.42308V18.5769Z" />
    <path d="M5.96134 7.66688H9.4998C9.98339 7.66688 10.3844 7.26585 10.3844 6.78226C10.3844 6.29867 9.98339 5.89764 9.4998 5.89764H5.96134C5.47775 5.89764 5.07673 6.29867 5.07673 6.78226C5.07673 7.26585 5.47775 7.66688 5.96134 7.66688Z" />
    <path d="M13.0383 10.6154H5.96134C5.47775 10.6154 5.07673 11.0164 5.07673 11.5C5.07673 11.9836 5.47775 12.3846 5.96134 12.3846H13.0383C13.5219 12.3846 13.9229 11.9836 13.9229 11.5C13.9229 11.0164 13.5219 10.6154 13.0383 10.6154Z" />
    <path d="M13.0383 15.3332H5.96134C5.47775 15.3332 5.07673 15.7342 5.07673 16.2178C5.07673 16.7014 5.47775 17.1024 5.96134 17.1024H13.0383C13.5219 17.1024 13.9229 16.7014 13.9229 16.2178C13.9229 15.7342 13.5219 15.3332 13.0383 15.3332Z" />
  </svg>
);

export const PersonalIcon = ({ className }) => (
  <svg
    data-testid="personal-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="currentColor"
  >
    <path d="M15.5769 0H7.67947C4.72358 0 2.3205 2.40308 2.3205 5.35897V5.64103H0.910241C0.447677 5.64103 0.0640869 6.02462 0.0640869 6.48718C0.0640869 6.94974 0.447677 7.33333 0.910241 7.33333H2.3205V10.1538H0.910241C0.447677 10.1538 0.0640869 10.5374 0.0640869 11C0.0640869 11.4626 0.447677 11.8462 0.910241 11.8462H2.3205V14.6667H0.910241C0.447677 14.6667 0.0640869 15.0503 0.0640869 15.5128C0.0640869 15.9754 0.447677 16.359 0.910241 16.359H2.3205V16.641C2.3205 19.5969 4.72358 22 7.67947 22H15.5769C18.5328 22 20.9359 19.5969 20.9359 16.641V5.35897C20.9359 2.40308 18.5328 0 15.5769 0ZM19.2436 16.641C19.2436 18.6605 17.5964 20.3077 15.5769 20.3077H7.67947C5.65999 20.3077 4.01281 18.6605 4.01281 16.641V5.35897C4.01281 3.33949 5.65999 1.69231 7.67947 1.69231H15.5769C17.5964 1.69231 19.2436 3.33949 19.2436 5.35897V16.641Z" />
    <path d="M11.6282 11.8465C13.6476 11.8465 15.2948 10.1993 15.2948 8.17979C15.2948 6.1603 13.6476 4.51312 11.6282 4.51312C9.60867 4.51312 7.96149 6.1603 7.96149 8.17979C7.96149 10.1993 9.60867 11.8465 11.6282 11.8465ZM11.6282 6.20543C12.7112 6.20543 13.6025 7.09671 13.6025 8.17979C13.6025 9.26287 12.7112 10.1541 11.6282 10.1541C10.5451 10.1541 9.65379 9.26287 9.65379 8.17979C9.65379 7.09671 10.5451 6.20543 11.6282 6.20543Z" />
    <path d="M15.7687 14.5311C15.34 13.2675 14.1666 12.41 12.8241 12.41H10.4323C9.08972 12.41 7.91638 13.2675 7.48767 14.5311L6.87844 16.37C6.73177 16.81 6.96869 17.2952 7.40869 17.4418C7.84869 17.5885 8.33382 17.3516 8.48049 16.9116L9.08972 15.0726C9.28151 14.4972 9.82305 14.1136 10.4323 14.1136H12.8241C13.4333 14.1136 13.9748 14.4972 14.1666 15.0726L14.7759 16.9116C14.9 17.2613 15.2272 17.487 15.5769 17.487C15.6672 17.487 15.7574 17.4757 15.8477 17.4418C16.2877 17.2952 16.5359 16.81 16.3779 16.37L15.7687 14.5311Z" />
  </svg>
);

export const SearchIcon = ({ className }) => (
  <svg
    data-testid="search-icon"
    className={`w-4 h-4 text-brand-primary ${className}`}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
    />
  </svg>
);

export const WorkplaceIcon = ({ className }) => (
  <svg
    data-testid="workplace-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="currentColor"
  >
    <path d="M17.0245 4.98831C15.9758 2.99018 14.2095 1.54403 12.0458 0.936859C10.5224 0.506324 9.31906 0.473206 8.98788 0.473206C7.99433 0.473206 7.01183 0.605678 6.06244 0.870623C3.90977 1.46675 2.12139 2.91291 1.02849 4.96623C-0.108568 7.09683 -0.318316 9.61381 0.476519 11.9873C1.62461 15.3653 3.5565 18.368 6.22803 20.896C7.00079 21.6357 7.99433 22 8.98788 22C9.98142 22 10.975 21.6357 11.7477 20.896C14.4192 18.357 16.3511 15.3653 17.5213 11.921C18.3051 9.61381 18.1174 7.09683 17.0245 4.99935V4.98831ZM15.9316 11.4463C14.8719 14.5705 13.0835 17.3414 10.6107 19.6928C9.68336 20.5759 8.2924 20.5759 7.36509 19.6928C4.89227 17.3414 3.10389 14.5705 2.01099 11.358C1.39279 9.51445 1.56942 7.47217 2.49672 5.75003C3.36884 4.10516 4.79291 2.94603 6.50402 2.47133C7.30989 2.25054 8.14888 2.14015 8.98788 2.14015C9.26386 2.14015 10.2795 2.17327 11.5932 2.54861C13.3043 3.03434 14.7173 4.18244 15.5563 5.78314C16.4505 7.47217 16.594 9.52549 15.9316 11.4684V11.4463Z" />
    <path d="M8.98792 4.88898C6.70277 4.88898 4.84815 6.74359 4.84815 9.02874C4.84815 11.3139 6.70277 13.1685 8.98792 13.1685C11.2731 13.1685 13.1277 11.3139 13.1277 9.02874C13.1277 6.74359 11.2731 4.88898 8.98792 4.88898ZM8.98792 11.5126C7.61904 11.5126 6.50406 10.3976 6.50406 9.02874C6.50406 7.65986 7.61904 6.54488 8.98792 6.54488C10.3568 6.54488 11.4718 7.65986 11.4718 9.02874C11.4718 10.3976 10.3568 11.5126 8.98792 11.5126Z" />
  </svg>
);

export const EyeOpenIcon = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z" />
  </svg>
);

export const EyeClosedIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path d="M12.01 20c-5.065 0-9.586-4.211-12.01-8.424 2.418-4.103 6.943-7.576 12.01-7.576 5.135 0 9.635 3.453 11.999 7.564-2.241 4.43-6.726 8.436-11.999 8.436zm-10.842-8.416c.843 1.331 5.018 7.416 10.842 7.416 6.305 0 10.112-6.103 10.851-7.405-.772-1.198-4.606-6.595-10.851-6.595-6.116 0-10.025 5.355-10.842 6.584zm10.832-4.584c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z" />
  </svg>
);

ArrowIcon.propTypes = {
  className: PropTypes.string,
};

CheckIcon.propTypes = {
  className: PropTypes.string,
};

ClockIcon.propTypes = {
  className: PropTypes.string,
};

HomeIcon.propTypes = {
  className: PropTypes.string,
};

LikeIcon.propTypes = {
  className: PropTypes.string,
};

MoreIcon.propTypes = {
  className: PropTypes.string,
};

NewsIcon.propTypes = {
  className: PropTypes.string,
};

PersonalIcon.propTypes = {
  className: PropTypes.string,
};

SearchIcon.propTypes = {
  className: PropTypes.string,
};

WorkplaceIcon.propTypes = {
  className: PropTypes.string,
};
